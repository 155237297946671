/* You can add global styles to this file, and also import other style files */

$gutter: 1rem; //for primeflex grid system
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* global styles */

.top-30 {
    top: 30%;
}
.height-50 {
    height: 50%;
}
.p-picklist-item:not(.cdk-drag-disabled) {
    cursor: move;
    z-index: 1130 !important;
}

.full-width {
    span {
        width: 100%;
    }
    width: 100%;
}
